import './login.css';
import React, {useState} from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useNavigate, useParams } from "react-router-dom";
import {
  Link
} from "react-router-dom";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZ5UF2A6Q4D-LzE5B8Fb4xT_Xg43glvCA",
  authDomain: "renobuddy-b78cf.firebaseapp.com",
  projectId: "renobuddy-b78cf",
  storageBucket: "renobuddy-b78cf.appspot.com",
  messagingSenderId: "945906089355",
  appId: "1:945906089355:web:45589ee8a659bc2631438e",
  measurementId: "G-PQ554LCWRS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default function Login() {
  const auth = getAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [isInvalidCredentials, setIsInvalidCredentails] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  function signInUser(e) {
    e.preventDefault();  
    signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    navigate("/projectsummary");
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    if(errorCode == "auth/user-not-found" || errorCode == "auth/invalid-password") {
      setIsInvalidCredentails(true);
      setLoginErrorMessage("Invalid user credentials. Please try again.")
    }
  });
  }
  
function validateAndSetUsername(e) {
    const user = e.trim();
    if(user) {
        setUsername(user);
    }
}

function validateAndSetEmail(e) {
  setIsInvalidCredentails(false);
  const userEmail = e.trim();
  if(userEmail) {
      setEmail(userEmail);
  }
}


function validateAndSetPassword(e) {
  setIsInvalidCredentails(false);
  const userPassword = e.trim();
  if(userPassword) {
      setPassword(userPassword);
  }
}

  return(
    <div className="login-wrapper">
      <h1>Log in to existing account</h1>
      <form>
        <label>
          Email<br/>
          <input id="inputEmail" type="text" onChange={e => validateAndSetEmail(e.target.value)}
          style={{
            border: isInvalidCredentials ? '1px solid red' : '1px solid black'
          }}/>
        </label>  
        <br/>      
        <label>
          Password<br/>
          <input type="password" id="inputPassword" onChange={e => validateAndSetPassword(e.target.value)}
          style={{
            border: isInvalidCredentials ? '1px solid red' : '1px solid black'
          }}          
          />
          <br/>
        </label>
        <div>
        <br/>
          <button type="submit" onClick={ signInUser }> Submit </button>
        </div>
        <div style = {{visibility: isInvalidCredentials ? 'visible' : 'hidden'}} > {loginErrorMessage} </div>
      </form>
      <h6>Don't have an account? <Link to={{pathname: `/register`}}>Register here.</Link></h6>
    </div>
  )
}