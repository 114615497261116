import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyDZ5UF2A6Q4D-LzE5B8Fb4xT_Xg43glvCA",
    authDomain: "renobuddy-b78cf.firebaseapp.com",
    projectId: "renobuddy-b78cf",
    storageBucket: "renobuddy-b78cf.appspot.com",
    messagingSenderId: "945906089355",
    appId: "1:945906089355:web:45589ee8a659bc2631438e",
    measurementId: "G-PQ554LCWRS"
};
  
// Initialize Firebase
 const init = initializeApp(firebaseConfig);
 const firebase = getAuth(init);    

export default firebase;