import Header from './CommonHeader';
import './LandingPage.css';
import React from 'react';


export default function ContactPage() {
    return (
        <div>
            <Header/> 
            <div class = "commonPageMargin landingPageText">
            <h1>Contact Us</h1>

            <p>Experiencing an issue with the app?
             Have a feature request? Feel free to contact us at <a href="mailto:renovationrocket@yahoo.com">renovationrocket@yahoo.com</a>.
             We will try to respond within 24 hours. We would love to hear from you!
             <br/><br/>
             Thank you for your support!
            </p>
            </div>
        </div>
      )    
}