
import React from 'react';
function Error() {

    return (    
        <div> 
            <h1>An error has occurred!</h1>
        </div>       
    ); 

}

export default Error;