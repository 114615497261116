import './App.css';
import { format } from 'date-fns'
import { getAuth, onAuthStateChanged } from "firebase/auth";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import {
    Link, useNavigate
  } from "react-router-dom";
import Header from './CommonHeader';
import {Helmet} from "react-helmet";

export default function ProjectOverview() {
    const [isLoaded, setIsLoaded] = useState("");
    const [projects, setProjects] = useState([]);
    const [elementSelected, setElementSelected] = useState(false);
    const [projectNameSelected, setProjectNameSelected] = useState([]);
    const [show, setShow] = useState(false);
    const [projectsFiltered, setProjectsFiltered] = useState([]);
    const navigate = useNavigate();

   const REACT_APP_BACKEND_HOST= 'https://renorocket-backend.com';
//   const REACT_APP_BACKEND_HOST= 'http://localhost:5000'; 

    const firebaseConfig = {
        apiKey: "AIzaSyDZ5UF2A6Q4D-LzE5B8Fb4xT_Xg43glvCA",
        authDomain: "renobuddy-b78cf.firebaseapp.com",
        projectId: "renobuddy-b78cf",
        storageBucket: "renobuddy-b78cf.appspot.com",
        messagingSenderId: "945906089355",
        appId: "1:945906089355:web:45589ee8a659bc2631438e",
        measurementId: "G-PQ554LCWRS"
    };
      
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);    

    onAuthStateChanged(auth, (user) => {
        if (user) {  
          if(!isLoaded) {
          const email = auth.currentUser.email; 
          fetch(`${REACT_APP_BACKEND_HOST}/projects/${email}`)
          .then(res => res.json())
          .then(
            (result) => {
              if(result) {
              const proj = result.reduce(( res, currentValue ) => {
                (res[currentValue['projectStatus']] = res[currentValue['projectStatus']] || []).push(
                  currentValue
                );
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return res;
              }, {});
              setProjectsFiltered(proj);
              }
              setProjects(result);
              setIsLoaded("true");
              console.log(result);
            },
            (error) => {
               
            }
          )
        }
          
        } else {
          navigate("/login")
        }
    });
      
    //   useEffect(() => {
    //     if(auth && auth.currentUser) {
    //     const email = auth.currentUser.email; 
    //     fetch(`${REACT_APP_BACKEND_HOST}/projects/${email}`)
    //     .then(res => res.json())
    //     .then(
    //       (result) => {
    //         setProjects(result);
    //         setIsLoaded("true");
    //         console.log(result);
    //       },
    //       (error) => {
             
    //       }
    //     ) 
    //   }
    // }, [auth.currentUser]);  
  
    
    function focusRow(key, name) {
        setElementSelected(key);
        setProjectNameSelected(name);
    }
    
    function showModal(e) {
        setShow(true);
      }
    
    function closeModal() {
        setShow(false);
    }

    function deleteRow() {
        fetch(`${REACT_APP_BACKEND_HOST}/delete/${elementSelected}`,
        {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PUT',       
        },
        )
        .then(res => res.text())
        .then(
          removeRowFromView()  
        )
        .then(closeModal());     
      }

    function removeRowFromView() {
      const newProjs = projects.filter(function(project) { 
        return project.projectId !== elementSelected; 
      });
      setProjects(newProjs);
      groupProjects(newProjs);
    }  
    function groupProjects(projs) {
      const proj = projs.reduce(( res, currentValue ) => {
        (res[currentValue['projectStatus']] = res[currentValue['projectStatus']] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return res;
      }, {});  
      setProjectsFiltered(proj);          
    }    

    function createTable(projectList, tableTitle) {
      if(!projectList) {
        return;
      }
      return <table class="projectOverviewTable">
        <caption>{tableTitle}</caption>
      <thead>
        <tr>
          <th>Project Title</th>
          <th>Total Cost</th>
          <th>Start Date</th>
          <th>End Date</th>
        </tr>
      </thead>
      <tbody>
      { projectList.map((val,key) => 
       {
        return (
          
          <tr key = {key} 
          style={{
            backgroundColor : elementSelected === val.projectId ? '#1C3685' : 'white',
            color: elementSelected === val.projectId ? 'white' : 'black'
          }}              
          onClick = {(e) => focusRow(val.projectId, val.name)} >
            <td>{val.name}</td>
            <td>{val.totalCost}</td>
            <td>{new Date(val.startDate[0], val.startDate[1]-1, val.startDate[2]).toLocaleDateString("en-US")}</td>
            <td>{new Date(val.endDate[0], val.endDate[1]-1, val.endDate[2]).toLocaleDateString("en-US")}</td>
          </tr>
        );
      }
      ) }
      </tbody>
    </table>        
    }

    return (
      <div>
        <Helmet>
          <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
        </Helmet>
        <Header/>
        <div className="App" id="projectSummaryPage">
        <h1>Projects Overview</h1>
        {createTable(projectsFiltered["TO_DO"], "To Do") }  
        {createTable(projectsFiltered["IN_PROGRESS"], "In Progress")}  
        {createTable(projectsFiltered["COMPLETED"], "Completed")}  
        <button id = "buttonDeleteProject" style={{
                visibility : elementSelected && projects.length>0  ? "visible" : "hidden" 
              }}   class="btnWeak" onClick = {(e) => showModal()}>Delete</button>
        <button id = "buttonProjectDetails" style={{
                visibility : elementSelected && projects.length>0 ? "visible" : "hidden" 
              }} class="btnWeak"><Link to={ {pathname: `/project/${elementSelected}`, state: { edit: true }} } class="link">Project Details</Link></button>
          <Modal show={show} centered>
            <Modal.Header closeButton onClick= {(e) => closeModal()}>
              <Modal.Title>Delete Project {projectNameSelected}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure want to delete this project?</Modal.Body>
            <Modal.Footer>
            <button id = "buttonCancelModal" class="btnWeak" onClick = {(e) => closeModal()}>Cancel</button>
            <button id = "buttonDeleteProjectModal" class="btnEmphasized" onClick = {(e) => deleteRow()}>Delete</button>
            </Modal.Footer>
          </Modal>   
          <Link to="/project/new" class="link"><button id = "buttonNewProject" class="btnEmphasized">New Project</button></Link>           
    </div>
    </div>
    );      
  }