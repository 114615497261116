import Header from './CommonHeader';
import {
    Link
  } from "react-router-dom";
import './LandingPage.css';
import { getAuth, onAuthStateChanged } from "firebase/auth";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import React from 'react';
import {useState,} from 'react';

export default function LandingPage() {

  const [currentUser, setCurrentUser] = useState("");

  const firebaseConfig = {
    apiKey: "AIzaSyDZ5UF2A6Q4D-LzE5B8Fb4xT_Xg43glvCA",
    authDomain: "renobuddy-b78cf.firebaseapp.com",
    projectId: "renobuddy-b78cf",
    storageBucket: "renobuddy-b78cf.appspot.com",
    messagingSenderId: "945906089355",
    appId: "1:945906089355:web:45589ee8a659bc2631438e",
    measurementId: "G-PQ554LCWRS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();    

onAuthStateChanged(auth, (user) => {
    if (user) {
      if(!currentUser) {
        setCurrentUser(user);
      }
      // ...
    } else {
      if(currentUser) {
        setCurrentUser("");
      }
    }
});

return (
    <div>
        <Header/> 
        <div class = "commonPageMargin landingPageText">
        <h1>Renovation Rocket</h1>
        <h2>Renovation management made easy</h2>

        <p>Renovation rocket is your virtual checklist for renovation projects: manage budgets 
            timelines of home improvement projects with ease.
        </p>
           { currentUser ? getDescriptionSignedIn(): getDescriptionSignedOut()}  
        </div>
    </div>
  );
  
  function getDescriptionSignedOut() {
    return <>
        <p>
            We're now live! Sign up today for unlimited access. <br/> <br/> <br/>
            <Link to="/register"><button id = "btnSignUp" class="btnEmphasized">Sign Up</button></Link>
        </p>
    </> 
  }; 

  function getDescriptionSignedIn() {
    return <>
       You can view a list of all your projects <Link to="/projectsummary">here</Link>.
    </>
  };
      
}