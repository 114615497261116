import React from 'react'
import './CommonHeader.css';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {useState,} from 'react';
import { initializeApp } from "firebase/app";
import {
  Link, useNavigate
} from "react-router-dom";
import check from './logo.png';
import firebase from './firebase';

function Header() {

  const [currentUser, setCurrentUser] = useState("");

    const navigate = useNavigate();
    const auth = firebase;
    onAuthStateChanged(auth, (user) => {
        if (user) {
          if(!currentUser) {
            setCurrentUser(user);
          }
          // ...
        } else {
          if(currentUser) {
            setCurrentUser("");
          }
        }
    });

    function signOutUser() {
        signOut(auth).then(() => {
          navigate("/");            
            // Sign-out successful.
          }).catch((error) => {
            // An error happened.
        });    
    }

  function getMenuSignedIn() {
    return <>
    <div class="dropdown">
        <button class = "buttonDropdown userInfo">{auth.currentUser.email}</button>
            <div class="dropdown-content">
            {/* <button class = "buttonDropdownItem">Account Settings</button> */}
            <Link to="/projectsummary" class="link buttonDropdownItem">My Projects</Link>           
            <button class = "link buttonDropdownItem" onClick={signOutUser}>Sign Out</button>
            </div>
        </div>
    </> 
  }; 

  function getMenuSignedOut() {
    return <>
        <Link to="/login" class="link userInfo"><button id = "b" class="btnWeak">Login</button> </Link>
    </>
  };

  return (
    <div id = "header">
     <Link to="/"><img src={check} id = "success" alt="logo" /></Link>
     <Link to="/contact" class="link common-header-link">Contact Us</Link>           
      { currentUser ? getMenuSignedIn(): getMenuSignedOut()}
    </div>
  )
}

export default Header