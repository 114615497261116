import './CreateSuccess.css';
import './common.css'
import React from 'react';
import check from './check.svg';
import {Link} from "react-router-dom";

function CreateSuccess() {
    return < CreateSuccessPage />
}

class CreateSuccessPage extends React.Component {

    render() {
        return (
            <div class = "commonPageMargin">
                <img src={check} id = "success" alt="logo" />
                <h1 id = "pageTitleSuccess">Change successfully made.</h1>
                <Link to="/project/new"><button id = "buttonNewProject" class="btnEmphasized">New Project</button></Link>
                <Link to="/projectsummary"><button id = "buttonProjectOverview" class="btnSecondary">Project Overview</button></Link>
            </div>
        );
    }
}

export default CreateSuccess;