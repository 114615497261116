import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ProjectSummary from './ProjectSummaryHooks';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import {
    BrowserRouter as Router,
    Route,
    Routes,
  } from "react-router-dom";
import CreateProject from './CreateProject.js';
import CreateSuccess from './CreateSuccess.js';
import Error from './Error.js';
import Login from './Login.js';
import Signup from './Signup.js';
import LandingPage from './LandingPage.js';
import ContactPage from './ContactPage.js';


import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyDZ5UF2A6Q4D-LzE5B8Fb4xT_Xg43glvCA",
    authDomain: "renobuddy-b78cf.firebaseapp.com",
    projectId: "renobuddy-b78cf",
    storageBucket: "renobuddy-b78cf.appspot.com",
    messagingSenderId: "945906089355",
    appId: "1:945906089355:web:45589ee8a659bc2631438e",
    measurementId: "G-PQ554LCWRS"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
        <Routes>
            <Route exact path="/projectsummary" element={<ProjectSummary/>}/>      
            <Route path="project/:projectId" element={<CreateProject/>}/>
            <Route path="/success" element={<CreateSuccess/>}/>
            <Route path="/login" element={<Login/>}/>;
            <Route path="/register" element={<Signup/>}/>;
            <Route path="/error" element={<Error/>} />;
            <Route path="/contact" element={<ContactPage />} />;
            <Route path="/" element={<LandingPage/>} />;
        </Routes> 
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
