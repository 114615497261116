import './common.css';
import React, {useState} from 'react';
import { getAuth, deleteUser, createUserWithEmailAndPassword } from "firebase/auth";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { useNavigate, Link } from "react-router-dom";
import './Signup.css';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZ5UF2A6Q4D-LzE5B8Fb4xT_Xg43glvCA",
  authDomain: "renobuddy-b78cf.firebaseapp.com",
  projectId: "renobuddy-b78cf",
  storageBucket: "renobuddy-b78cf.appspot.com",
  messagingSenderId: "945906089355",
  appId: "1:945906089355:web:45589ee8a659bc2631438e",
  measurementId: "G-PQ554LCWRS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default function Signup() {
  const auth = getAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordDuplicate, setPasswordDuplicate] = useState("");
  const [validDuplicate, setValidDuplicate] = useState("");
  const [isPasswordEntered, setIsPasswordEntered] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
//  const REACT_APP_BACKEND_HOST = 'http://renovationrocketbackend-env.eba-zdpr2awr.us-east-2.elasticbeanstalk.com';
  const REACT_APP_BACKEND_HOST = 'http://localhost:5000'; 

  function validRegistrationCredentials() {
    return username && email && password;
  }

  function createUser(e) {
    e.preventDefault();
    if(validRegistrationCredentials()) {

      if(password!=passwordDuplicate) {
        setValidDuplicate(false);  
        setErrorMessage("The entered passwords do not match.");
        return;  
      }      

    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        const user = userCredential.user;
        const reqBody = {
          username : username,
          email: email
      }        
      return fetch(`${REACT_APP_BACKEND_HOST}/user/create`,
      {
          headers: {
              'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(reqBody)
      }
      ).then(
           res => { 
           if(!res.ok) {
            if(res.status=='409') {
              setErrorMessage("This username has already been taken.");
              const user = auth.currentUser;
              deleteUser(user).then(() => {
                // User deleted.
              }).catch((error) => {
                // An error ocurred
                // ...
              });             
              return null;
            }
            else {
              setErrorMessage("An error has occured! Please try processing your request later.");
            }              
              throw new Error(`HTTP error! Status:`);
           }             
              navigate("/success");
           }
           )
      .catch(
          (error) => {
            setErrorMessage("An error has occured! Please try processing your request later.");
          }
      );         
      })
      .catch((error) => {
        const errorCode = error.code;
        if(errorCode == 'auth/email-already-in-use') {
          setErrorMessage("The chosen email address is associated with another account.")    
        }
        else {
          setErrorMessage("An error has occured! Please try processing your request later.");
        }
        return;
      });  
    }
  }  

  function validateAndSetEmail(e) {
    const userEmail = e.trim();
    if(userEmail.length > 320 || !userEmail.includes("@")) {
      setErrorMessage("Please enter a valid email address.");
      setEmail("");
    }
    else {
      setEmail(userEmail);
    }
  }

function validateAndSetUsername(e) {
    const user = e.trim();
    if(user) {
      setUsername(user);
    }
    else {
      setUsername("");
    }
}

function validateAndSetPassword(e) {
    setIsPasswordEntered(true);
// check password matches duplicate
    const userPassword = e.trim();
    if(userPassword.length < 5) {
      setPassword("");
      setErrorMessage("Password needs to be at least five characters long.");
    }
    else {
      setPassword(userPassword);
      setErrorMessage("");      
    }
}

function validatePasswordDuplicate(passwordDuplicate) {
  setPasswordDuplicate(passwordDuplicate);
}

  return(
    <div className="login-wrapper">
      <h1>Create an account</h1>
      <form autocomplete="off">
        <label>
          Username<div class = "requiredField">*</div> <br/>
          <input type="text" onChange={e => validateAndSetUsername(e.target.value)}/>
        </label>
        <br/>
        <label>
          Email<div class = "requiredField">*</div> <br/>
          <input type="text" onChange={e => validateAndSetEmail(e.target.value)}/>
        </label>
        <br/>        
        <label>
          Password<div class = "requiredField">*</div> <br/>
          <input type="password" onChange={e => validateAndSetPassword(e.target.value)} style={{
          border: validDuplicate || validDuplicate==="" ? '1px solid black' : '1px solid red'}}/>
        </label>
        <br/>
        <label>
          Re-enter password<div class = "requiredField">*</div> <br/>
          <input type="password" style={{
          border: validDuplicate || validDuplicate==="" ? '1px solid black' : '1px solid red'}}  onChange = {e => validatePasswordDuplicate(e.target.value)}/>
        </label>
        <br/>        
        <div>
          <button type="submit" className = {((validRegistrationCredentials()))?"btnEmphasized":"btnDisabled"} onClick={ createUser }>Submit</button>
        </div>
        <p>Already have an account? Sign in <Link to={{pathname: `/login`}}>here</Link>.</p>
        <p>{errorMessage}</p>
      </form>
    </div>
  )

}
