import './CreateProject.css';
import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import Header from './CommonHeader'
import {Helmet} from "react-helmet";
import { format } from 'date-fns'

function CreateProject() {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [minCost, setMinCost] = useState('');
    const [cost, setCost] = useState('');
    const [description, setDescription] = useState('');
    const currDate = new Date();
    const [startDate, setStartDate] = useState([currDate.getFullYear(),currDate.getMonth()+1,currDate.getDate()]);
    const [endDate, setEndDate] =  useState([currDate.getFullYear(),currDate.getMonth()+1,currDate.getDate()]);
    const [validCost, setValidCost] = useState(true);
    const [validName, setValidName] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [modified, setModified] = useState(false);
    const [isRange, setIsRange] = useState(false);   
    const [projectStatus, setProjectStatus] = useState("TO_DO");
    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [serverErrorMessage, setServerErrorMessage] = useState("");
    const [serverError, setServerError] = useState(false);
    const REACT_APP_BACKEND_HOST= 'https://renorocket-backend.com';
  //  const REACT_APP_BACKEND_HOST = 'http://localhost:5000'; 

    let { projectId } = useParams();
    const firebaseConfig = {
        apiKey: "AIzaSyDZ5UF2A6Q4D-LzE5B8Fb4xT_Xg43glvCA",
        authDomain: "renobuddy-b78cf.firebaseapp.com",
        projectId: "renobuddy-b78cf",
        storageBucket: "renobuddy-b78cf.appspot.com",
        messagingSenderId: "945906089355",
        appId: "1:945906089355:web:45589ee8a659bc2631438e",
        measurementId: "G-PQ554LCWRS"
    };
      
      // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;

          // ...
        } else {
          navigate("/login")
        }
    });

    if( projectId != "new") {
        useEffect(() => {
            fetch(`${REACT_APP_BACKEND_HOST}/project/${projectId}`)
            .then(res => res.json())
            .then(
            (result) => {
                setName(result.name);
                setCost(result.totalCost);
                setDescription(result.description);
                setStartDate(result.startDate);
                setEndDate(result.endDate);
                setIsRange(result.isRange);
                setProjectStatus(result.projectStatus);
                setValidName(true);
            },
            (error) => {

            }
            )
        }, []);   
    }     

    async function saveProject() {
        const reqBody = {
            name: name.trim(),
            totalCost: !cost? minCost : cost.trim(),
            description: description.trim(),
            startDate: startDate,
            endDate: endDate,
            projectStatus: projectStatus,
            isEstimate: isRange,
            projectId: projectId == 'new' ? '' : projectId
        }      
        const email = auth.currentUser.email;  
        return fetch(`${REACT_APP_BACKEND_HOST}/project/add/${email}`,
        {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(reqBody)
        }
        ).then(
             res => { 
             if(!res.ok) {
                if(res.status=='409') {
                    setValidName(false);
                    setInProgress(false);
                    setNameErrorMessage("A project with this name already exists.");
                    return null;
                }
                else {
                    throw new Error(`HTTP error! Status:`);
                }
             }             
             return res }
             )
        .catch(  
            (error) => {
                setServerErrorMessage("An error occurred while processing your request. Please try again later."); 
                setInProgress(false);              
            }
        );   
    }

    async function handleSubmit(e) {
        if(!validCost || !validName) {
            return;
        } 

        e.preventDefault();
        
        setInProgress(true);
        const response = await saveProject();

        if(response) {
            navigate("/success");
        }
    } 

    function validateCost(e) {
        setServerError("");
        const cost = e.target.value.trim();
        var regex  = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/ ;
        //if both min and max are provided, min must be less than or equal to max.
        var valid = regex.test(cost) && (!cost||!minCost||minCost<=cost);
        setValidCost(!cost || valid);

        if(valid) {
            setCost(cost);
        }
    }

    function validateName(e) {
        setServerError("");
        setModified("true");
        const name = e.target.value.trim();
        // check for alphanumeric and spaces
        if(name.length === 0) {
            setNameErrorMessage("Please enter a valid project name");
            setValidName(false);
            return;            
        }
        else {
            setValidName(true);
            setName(name);
        }
    }

    function setInRange() {
        setIsRange(!isRange);
        setMinCost();
    }

    function getDateObject(dateArr) {
        return new Date(dateArr[0],dateArr[1]-1,dateArr[2]);
    }

    return (    
        <div>
        <Helmet>
          <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
        </Helmet>            
            <Header/> 
        <div id = "projectSummaryPage">
        <h1>{projectId=='new' ? 'New Project' : `${name}`}</h1>
        <form>
            <label>
                Project Name <div class = "requiredField">*</div> 
                <input type = "text" value = {name} id = "inputName" style={{
          border: !modified||validName ? '1px solid black' : '1px solid red'
        }} onChange={e => setName(e.target.value)} name="name" maxLength="200" onBlur = {validateName} />  
            <div style = {{visibility: !modified||validName ? 'hidden' : 'visible'}} > {nameErrorMessage} </div>
            </label> 
            <label class = "formLabel">
                Project Status <br />
                <select value={projectStatus} onChange = {e => setProjectStatus(e.target.value)} id="status-select">
                    <option value="TO_DO">To Do</option>
                    <option value="IN_PROGRESS">In Progress</option>
                    <option value="COMPLETED">Completed</option>
                </select>
            </label> <br/>
            <label class = "formLabel">
                Budget <br /> 
                <input type="checkbox" name="showRange" id="checkboxShowRange" class = "formItem" value={isRange} onClick={setInRange} />  
                <label for="showRange" id = "labelRangeCheckbox">Add budget range</label>               
                <div id = "dates">                     
                <input id="inputMinCost" type = "text" value = {minCost} placeholder = {isRange?"min":""} onChange = {e => setMinCost(e.target.value)} class = "inputWidth formInputCost" name="totalCost" maxLength="10" onBlur = {validateCost} style={{
          border: validCost ? ' 1px solid black' : ' 1px solid red'
           }}/> <div style={{
            visibility: isRange ? 'visible' : 'hidden'}}>-</div> 
                <input id="inputMaxCost" type = "text" value = {cost} placeholder = {isRange?"max":""} onChange = {e => setCost(e.target.value)} class = "inputWidth formInputCost" name="totalCost" maxLength="10" onBlur = {validateCost} style={{
          border: validCost ? ' 1px solid black' : ' 1px solid red',
          visibility: isRange ? 'visible' : 'hidden'
        }}/>        
        </div>
            <div style = {{visibility: validCost ? 'hidden' : 'visible'}} > Please enter a valid value for cost. </div>
            </label>
      <>
      <div id = "dates">
      <label class = "formLabel" >
        Start Date <br />
            <DatePicker
                class = "formItem" 
                selected = {getDateObject(startDate)}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={getDateObject(startDate)}
                endDate={getDateObject(endDate)}
                maxDate={getDateObject(endDate)}
            />
        </label>
        <br/> <br/>
        <label class = "formLabel">
            End Date
             <DatePicker
                selected={getDateObject(endDate)}  
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={getDateObject(startDate)}
                endDate={getDateObject(endDate)}
                minDate={getDateObject(startDate)}
            />
        </label>
        <br/> <br/> <br/>
        </div>
       </>                       
        <label class = "formLabel">
            Description <br/>
            <textarea name = "description" value = {description} class = "inputWidth inputName" id="textareaDescription" onChange={e => setDescription(e.target.value)} >
            </textarea>
        </label>
        <br/>
            <input type="submit" value={inProgress ? "Saving" : "Save"} className = { ((validCost&&validName&&!inProgress))?"btnEmphasized":"btnDisabled"} onClick={ handleSubmit }/>
        </form> 
        <div> {serverErrorMessage} </div>
        </div>  
        </div> 
    ); 
}

export default CreateProject;